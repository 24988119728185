import React from "react";
import { SvgIcon } from "@mui/material";

const ThicknessIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60.889 60.889">
      <path
        id="Path_2496"
        data-name="Path 2496"
        d="M49.016,18.571l-4.262,4.262L49.32,27.4H33.489V0H27.4V27.4H11.569l4.567-4.567-4.262-4.262L0,30.444,11.873,42.318l4.262-4.262-4.567-4.567H27.4v27.4h6.089v-27.4H49.32l-4.567,4.567,4.262,4.262L60.889,30.444Z"
      />
    </SvgIcon>
  );
};

export default ThicknessIcon;
