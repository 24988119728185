import React from "react";
import { SvgIcon } from "@mui/material";

const WidthIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64.503 41.737">
      <path
        id="Path_2498"
        data-name="Path 2498"
        d="M177.9,308h60.708a1.9,1.9,0,0,1,1.9,1.9V347.84a1.9,1.9,0,0,1-1.9,1.9H177.9a1.9,1.9,0,0,1-1.9-1.9V309.9A1.9,1.9,0,0,1,177.9,308Zm1.9,3.794v34.148h56.914V311.794Zm10.271,15.177h36.372l-2.453-2.453a1.9,1.9,0,1,1,2.683-2.683l5.691,5.691a1.9,1.9,0,0,1,0,2.683l-5.691,5.691a1.9,1.9,0,0,1-2.683-2.682l2.453-2.453H190.065l2.453,2.453a1.9,1.9,0,1,1-2.683,2.682l-5.691-5.691a1.9,1.9,0,0,1,0-2.683l5.691-5.691a1.9,1.9,0,1,1,2.683,2.683Z"
        transform="translate(-176 -308)"
      />
    </SvgIcon>
  );
};

export default WidthIcon;
