import React from "react";
import { SvgIcon } from "@mui/material";

const WeightIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 59.265 56.179">
      <path
        id="Path_2497"
        data-name="Path 2497"
        d="M71.233,68.159,59.124,33.081a1.528,1.528,0,0,0-1.484-1.016H48.264a10.157,10.157,0,1,0-16.64-7.812A10.1,10.1,0,0,0,35.3,32.066H25.843a1.6,1.6,0,0,0-1.484,1.016L12.171,68.159a1.57,1.57,0,0,0,1.484,2.109H69.748A1.593,1.593,0,0,0,71.233,68.159ZM41.7,19.957a4.284,4.284,0,0,1,4.3,4.3,4.218,4.218,0,0,1-4.3,4.219,4.284,4.284,0,0,1-4.3-4.3A4.218,4.218,0,0,1,41.7,19.957Z"
        transform="translate(-12.069 -14.089)"
      />
    </SvgIcon>
  );
};

export default WeightIcon;
