import React from "react";
import { SvgIcon } from "@mui/material";

const SpeedIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 102.353 88">
      <path
        id="Path_2470"
        data-name="Path 2470"
        d="M14.919,87.336A2.15,2.15,0,0,0,17.967,84.3C-11.09,55.162,9.119,4.313,51.177,4.313s62.267,50.847,33.2,80a2.15,2.15,0,0,0,3.048,3.032C119.169,55.517,97.1,0,51.177,0S-16.814,55.517,14.919,87.336Z"
        transform="translate(0 0)"
      />
      <path
        id="Path_2471"
        data-name="Path 2471"
        d="M12.563,69.564a1.075,1.075,0,0,0,1.525-1.515,38.311,38.311,0,0,1,.355-54.238C27.816.489,50.028-.61,64.687,11.078A1.076,1.076,0,0,0,66.026,9.4C50.513-2.972,27.087-1.815,12.928,12.286a40.451,40.451,0,0,0-.365,57.278Z"
        transform="translate(10.08 10.051)"
      />
      <path
        id="Path_2472"
        data-name="Path 2472"
        d="M8.908,26.736,33.819,1.824,35.345,3.35,10.39,28.3a3.49,3.49,0,1,1-1.482-1.568ZM7.237,28.462A1.334,1.334,0,1,0,8.571,29.8,1.335,1.335,0,0,0,7.237,28.462Z"
        transform="translate(43.94 21.403)"
      />
    </SvgIcon>
  );
};

export default SpeedIcon;
